import React from "react"
import { Link, graphql } from "gatsby"
import styled from "@emotion/styled"
import SEO from "../components/seo"
import Layout from "../components/layout"
import colors from "../styles/colors"
import dimensions from "../styles/dimensions"

import ProjectCard from "../components/_ui/ProjectCard"

const HeroContainer = styled("div")`
  padding-top: 7em;
  padding-bottom: 7em;
`

const WorkGrid = styled("div")`
  display: grid;
  grid-gap: 30px;

  grid-template-columns: 1fr 1fr;
  @media (max-width: ${dimensions.maxwidthMobile}px) {
    grid-template-columns: 1fr;
  }
`

const StyledLink = styled("div")`
  margin-top: 1em;
  a {
    color: ${colors.primary};
    text-decoration: none;
    font-weight: 600;
    font-size: 1em;
    border-bottom: 3px solid transparent;
    width: fit-content;
    padding-bottom: 0.1em;
    span {
      margin-left: 0.5em;
      transform: translateX(-8px);
      display: inline-block;
      transition: transform 400ms ease-in-out;
    }
  }
  a:hover {
    border-bottom: 2px solid ${colors.primary};
    transition: 100ms ease-in-out border-bottom;
    span {
      transform: translateX(0px);
      opacity: 1;
      transition: transform 150ms ease-in-out;
    }
  }
`
const HeroDescription = styled("div")`
  h3 {
    font-weight: 400;
  }
  a {
    color: currentColor;
    text-decoration: none;
    border-bottom: 2px solid currentColor;
  }
  a:hover {
    color: ${colors.primary};
    border-bottom: 2px solid ${colors.primary};
    transition: 100ms ease-in-out;
  }
`
const HomePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.nodes
  return (
    <Layout location={location} title={siteTitle}>
      <HeroContainer className="content">
        <SEO title="Home" />
        <HeroDescription>
          <h3>
            <h2>Hi! — I'm Angelica.</h2>
            <br /> I'm an AR/VR developer, Web developer, and UI/UX designer. I also write for my newsletter, 
            {" "}<a
              href="https://xrresearched.substack.com/"
              target="_blank"
              rel="noreferrer"
            >XR Researched</a>.{" "}You can check out some of my projects below.
          </h3>
        </HeroDescription>
        <StyledLink>
          <Link to="/about">
            See about me <span>→</span>
          </Link>
        </StyledLink>
      </HeroContainer>
      <div className="content">
        <WorkGrid>
          {posts.map((post, i) => {
            return (
              <ProjectCard
                thumbnail={post.frontmatter.thumbnail}
                key={i}
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                description={post.frontmatter.description}
                tags={post.frontmatter.tags}
                link={post.fields.slug}
              />
            )
          })}
        </WorkGrid>
      </div>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          tags
          description
          thumbnail {
            childImageSharp {
              sizes(maxWidth: 1000) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  }
`
